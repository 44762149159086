//====================================================
//  Function: Slider
//====================================================
function componentSwiper() {
  factorsSlider()
  stepsSlider()
  productsSlider()
}

function factorsSlider() {
  if ($('.js-slider-factors').length) {
    var inst = $('.js-slider-factors'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 0,
      lazy: true,
      loop: true,
      speed: 600,
      spaceBetween: 15,
      // autoplay: {
      //   delay: 4000,
      //   disableOnInteraction: true,
      // },
      // pagination: {
      //   el: swiperPagination,
      //   clickable: true,
      // },
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
    })
  }
}

function stepsSlider() {
  if ($('.js-slider-steps').length) {
    var inst = $('.js-slider-steps'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 0,
      lazy: true,
      loop: true,
      speed: 600,
      spaceBetween: 15,
      // autoplay: {
      //   delay: 4000,
      //   disableOnInteraction: true,
      // },
      // pagination: {
      //   el: swiperPagination,
      //   clickable: true,
      // },
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
    })
  }
}

function productsSlider() {
  if ($('.js-slider-products').length) {
    var inst = $('.js-slider-products'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 0,
      lazy: true,
      loop: false,
      speed: 600,
      // autoplay: {
      //   delay: 4000,
      //   disableOnInteraction: true,
      // },
      breakpoints: {
        200: {
          slidesPerView: 1,
        },
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
    })
  }
}
